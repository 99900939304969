import { Text, Flex, Box, Switch, Input, Button } from '../primitives'
export const Tokenomics = () => {
    return (
        <Box style={{
            zIndex: '0', display: 'grid'
        }}
            css={{
                '@sm': {
                    px: '$0',
                },
            }}

        >
            <Flex
                css={{
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    zIndex: '10',
                    flexDirection: 'column',
                    gap: 36,
                    '@bp600': {
                        flexDirection: 'row',
                        gap: 0,
                    },
                }}
            >
                <Flex className='w-full xl:w-5/12 order-2 lg:order-1 xl:order-1' justify='center' css={{ alignItems: 'flex-start', '@bp600': { alignItems: 'flex-end' } }}>
                    <Box className=" right-0 bottom-0 z-[-1] align-middle  px-4 pb-2 pt-8 order-1">
                        <img className='inset-0' src='/token-1.png' />
                    </Box>
                </Flex>
                <Box className='w-full xl:w-7/12 order-1 lg:order-2 xl:order-1' css={{ alignItems: 'flex-start', px: '$1', textAlign: 'center', gap: 18, '@bp600': { alignItems: 'flex-end', gap: 8 } }}>
                    <Box className="wow fadeInUp mx-auto max-w-[800px] text-center" data-wow-delay=".2s">
                        <h3 className="text-xl lg:text-2xl xl:text-3xl font-medium leading-normal uppercase mb-4"></h3>
                        <Text className="text-2xl lg:text-4xl xl:text-2xl font-medium leading-normal uppercase">🎉 CPO Token Airdrop</Text>
                        <Box className='border-b-4 border-indigo-500 mt-5'></Box>
                        <p className="mt-4 text-base leading-relaxed text-left">
                            We're distributing a generous 12% of our total token supply, a whopping 1 billion CPO tokens, in our grand Airdrop event! Here's how the rewards are divided:
                        </p>
                    </Box>
                    <Box className='text-left' style={{ display: 'grid' }} css={{ py: '$2', gap: 18, '@bp600': { alignItems: 'flex-end', gap: 8, flexDirection: 'row' } }}>
                        <Text css={{ color: 'gray3' }} className='text-2xl float-left'>
                            <Text className="text-l lg:text-base font-medium leading-normal mb-4">
                                <strong>🤩 1. Sign Up Bonus (1% of Total Airdrop): </strong>Join CoinPulse and instantly receive a 1% share of the total Airdrop value as a welcome bonus.
                            </Text>
                        </Text>
                        <Text css={{ color: 'gray3' }} className='text-2xl float-left'>
                            <Text className="text-l lg:text-base font-medium leading-normal mb-4">
                                <strong>🤝 2. Referral Earnings (3% of Total Airdrop): </strong>Build your network and earn a remarkable 3% of the total Airdrop value for every successful referral.
                            </Text>
                        </Text>
                        <Text css={{ color: 'gray3' }} className='text-2xl float-left'>
                            <Text className="text-l lg:text-base font-medium leading-normal mb-4">
                                <strong>💼 3. Investment Bonus (2% of Total Airdrop): </strong>Invest with us and get a 2% share of the Airdrop, boosting your returns.
                            </Text>
                        </Text>
                        <Text css={{ color: 'gray3' }} className='text-2xl float-left'>
                            <Text className="text-l lg:text-base font-medium leading-normal mb-4">
                                <strong>⛏️ 4. Airdrop Mining (4% of Total Airdrop): </strong>Start mining CPO tokens through our Airdrop Mining program, securing 4% of the total Airdrop value.
                            </Text>
                        </Text>
                        <Text css={{ color: 'gray3' }} className='text-2xl float-left'>
                            <Text className="text-l lg:text-base font-medium leading-normal mb-4">
                                <strong>🏆 5. Contests (2% of Total Airdrop): </strong>Participate in thrilling contests and win a share of the Airdrop worth 2%.
                            </Text>
                        </Text>
                        <Text css={{ color: 'gray3' }} className='text-sm float-right text-right'>
                            <a href='https://www.coinpulsepro.com/cpoToken'>Read More</a>
                        </Text>
                        <Button  className="flex w-full items-center justify-center rounded-md bg-primary p-3 text-base font-semibold transition duration-300 ease-in-out hover:bg-opacity-80">
                            <a href="/mining/tokenMining">
                                <Flex> <img src='/icons/cpoLight.png' className='h-6 w-6 bg-white rounded-full mx-2 animate-spin' />Start CPO Mining</Flex>
                            </a>
                        </Button>
                    </Box>
                </Box>

            </Flex>

        </Box>
    )
}  