import { ToastContext } from '@/context/ToastContextProvider'
import { v4 as uuid } from 'uuid';
import { getRandom } from '../Sanity/SanityContext';
import { styled } from '@/stitches.config'
import { FC, useEffect, useState, useContext } from 'react'
import { Button, Box, Flex, Text, Grid, Input, TextArea } from "../primitives"
import { Modal, ModalSize } from "../common/Modal"
import { ThumbUpIcon } from '@heroicons/react/solid';
import { useConnectModal } from '@rainbow-me/rainbowkit'
import { useWalletClient } from 'wagmi'
import { CSS } from '@stitches/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet } from '@fortawesome/free-solid-svg-icons';
import { faTelegram } from '@fortawesome/free-brands-svg-icons';

type RequestCompleted = {
    isRequestCompleted?: boolean
}

type Props = {
    onCloseComplete?: (data: RequestCompleted) => void,
    userId?: string,
    onRequestComplete?: (data: any) => void
}
const NewsletterModal: FC<Props> = ({
    onCloseComplete,
    userId,
    onRequestComplete
}) => {
    const { addToast } = useContext(ToastContext)
    const [open, setOpen] = useState(false)
    const [isRequestCompleted, setIsRequestCompleted] = useState(false)
    const [isDataValidated, setIsDataValidated] = useState<boolean>(false)

    const { openConnectModal } = useConnectModal()
    const { data: wallet } = useWalletClient()
    const subscribeNow = () => {
        setOpen(true)
    }

    const onSubmitHandler = async (event: any) => {

        // Stop the form from submitting and refreshing the page.
        event.preventDefault()
        setIsDataValidated(true)

        const data = {
            _id: uuid(),
            userId: userId?.toLowerCase(),
            name: event.target.name.value,
            email: event.target.email.value,
        }

        // Send the data to the server in JSON format.
        const JSONdata = JSON.stringify(data)
        // API endpoint where we send form data.
        const endpoint = '/api/subscribe'

        // Form the request for sending data to the server.
        const options = {
            // The method is POST because we are sending data.
            method: 'POST',
            // Tell the server we're sending JSON.
            headers: {
                'Content-Type': 'application/json',
            },
            // Body of the request is the JSON data we created above.
            body: JSONdata,
        }
        try {
            const response = await fetch(endpoint, options)
            await response.json().then((result) => {
                setIsDataValidated(false)
                setIsRequestCompleted(true)
            }).finally(() => {
                setIsDataValidated(false)
            })

        }
        catch (error: any) {
            const resMessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.response.data.detail ||
                error.message ||
                error.toString();
            //console.log('hello manish')
            console.log(resMessage)
        }


    }

    const trigger = (
        <>
            <a className="rounded-md btn-primary py-4 px-8 text-base font-semibold duration-300 ease-in-out flex gap-2" href="javascript:void(0)"> <ThumbUpIcon className="w-4 h-4 text-gray-400 my-1" />  Claim Bonus</a>
        </>
    )
    return (
        <>
            <a onClick={subscribeNow} className="rounded-md btn-primary py-4 px-8 text-base font-semibold duration-300 ease-in-out flex gap-2" href="https://t.me/coinpulseonline_bot?start=5043332234"> <FontAwesomeIcon icon={faTelegram} size="2xl" />Claim 10 USDT Welcome Bonus</a>
        </>
    )

    return (
        <Modal size={ModalSize.MD} open={open} title={`Claim your first free earning!`}
            onOpenChange={(open) => {
                if (
                    !open &&
                    onCloseComplete
                ) {
                    const data: RequestCompleted = {
                        isRequestCompleted: isRequestCompleted
                    }
                    onCloseComplete(data)
                }
                setOpen(open)
            }} trigger={trigger}>
            <Box css={{ p: '$4' }}>

                <form onSubmit={onSubmitHandler} method='post'>
                    {!isRequestCompleted && (<>
                        <Box className="-mx-4 flex flex-wrap items-center w-full px-4 text-center mb-8">
                            <Text className='capitalize font-extrabold text-2xl'> Claim and get up to $10 in your bonus balance</Text>
                        </Box>
                        <Box className="-mx-4 flex flex-wrap">
                            <Box className="w-full px-4">
                                <Box className="mb-8">
                                    <label for="name" className="mb-3 block text-sm font-medium text-dark">Your Name</label>
                                    <Input type="text" id='name' name='name' placeholder="Enter your name" required className="w-full" />
                                </Box>
                            </Box>
                        </Box>
                        <Box className="-mx-4 flex flex-wrap">
                            <Box className="w-full px-4">
                                <Box className="mb-8">
                                    <label for="email" className="mb-3 block text-sm font-medium text-dark">Your Email</label>
                                    <Input type="email" id='email' name='email' pattern='/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i' placeholder="Enter your email" required className="w-full" />
                                </Box>
                            </Box>
                        </Box>

                        <Box className="w-full px-4 text-center">
                            <Button type='submit' disabled={isDataValidated
                            } className="rounded-md bg-primary py-4 px-9 text-base font-medium transition duration-300 ease-in-out">

                                {isDataValidated ? (
                                    <>
                                        <svg
                                            className="-ml-1 mr-3 h-5 w-5 animate-spin "
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <circle
                                                className="opacity-25"
                                                cx="12"
                                                cy="12"
                                                r="10"
                                                stroke="currentColor"
                                                strokeWidth={4}
                                            ></circle>
                                            Subscribing...
                                            <path
                                                className="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                            ></path>
                                        </svg>
                                        Subscribing...
                                    </>
                                ) : (
                                    'Claim Now'
                                )}
                            </Button>
                        </Box>
                    </>)}
                    {isRequestCompleted && (
                        <Box className="-mx-4 flex flex-wrap">
                            <Box className="w-full px-4">
                                <Box className="mb-4 text-center">
                                    <Text css={{ fontFamily: '$body', fontSize: 'larger', fontSmooth: 'always', fontWeight: 'bold', marginBottom: '$2' }}>
                                        Thank You for Subscribing to Coinpulse Online!
                                    </Text>
                                </Box>
                                <Text css={{ fontSize: 'x-small' }}>
                                    Thank you for subscribing to Coinpulse Online! We are delighted to have you on board and excited to share our latest updates, offers, and valuable content with you.
                                </Text>
                                <Box className="mb-8 text-center">
                                    <Flex
                                        direction="column"
                                        align="center"
                                        css={{ mx: 'auto', py: '60px', maxWidth: '450px', gap: '$4' }}
                                    >
                                        <Text css={{ color: '$gray11' }}>
                                            <FontAwesomeIcon icon={faWallet} size="2xl" />
                                        </Text>
                                        <Text
                                            style="body1"
                                            css={{ color: '$gray11', textAlign: 'center', mb: '$4' }}
                                        >
                                            Connect your wallet and get first Bonus now up t0 $10 !
                                        </Text>
                                    </Flex>
                                </Box>
                                <Box className="mb-8 text-center">
                                    <a href='/claim'>Click here to know, How to claim?</a>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </form>
            </Box>
        </Modal>
    )

}
export default NewsletterModal