import { Text, Flex, Box, Switch, Input } from '../primitives'
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
export const Welcome = () => {
    return (
        <Box style={{
            zIndex: '0', display: 'grid'
        }}
            css={{
                '@sm': {
                    px: '$0',
                },
            }}

        >
            <Flex
                justify="between"
                css={{
                    alignItems: 'center',
                    zIndex: '10',
                    flexDirection: 'column',
                    gap: 36,
                    '@bp600': {
                        flexDirection: 'row',
                        gap: 0,
                    },
                }}
            >
                <Flex justify='center' css={{ alignItems: 'flex-start', '@bp600': { alignItems: 'flex-end' } }}>
                    <Box className=" right-0 bottom-0 opacity-30 lg:opacity-100 z-[-1] align-middle hidden px-4 pb-2 pt-8 lg:block">
                        <img className='inset-0' src='/inner-thumb.png' />
                    </Box>

                </Flex>
                <Box css={{ alignItems: 'flex-start', px: '$1', textAlign: 'center', gap: 18, '@bp600': { alignItems: 'flex-end', gap: 8 } }}>
                    <Box className="wow fadeInUp mx-auto max-w-[800px] text-center" data-wow-delay=".2s">
                        <h3 className="text-xl lg:text-2xl xl:text-3xl font-medium leading-normal uppercase mb-4"></h3>
                        <Text className="text-2xl lg:text-4xl xl:text-2xl font-medium leading-normal uppercase" css={{color:'$secondary12'}}>Why Choose Our Investment Platform</Text>
                        <Box className='border-b-4 border-indigo-500 mt-5'></Box>
                        <p className="mt-4 text-base leading-relaxed text-left">
                            Welcome to Coinpulse Online Financial Service, your premier destination for intelligent investing. We empower individuals and businesses to navigate the financial world with confidence and success. Discover expert insights, cutting-edge tools, and personalized strategies tailored to your unique investment goals. With our intuitive platform, you can seize opportunities, manage risks, and grow your wealth effortlessly. Join a vibrant community of savvy investors and unlock the potential of your financial future. Start your journey towards prosperity today at Coinpulse Online Financial Service.
                        </p>

                    </Box>
                    <Box className='text-left' style={{ display: 'grid' }} css={{ py: '$2', gap: 18, '@bp600': { alignItems: 'flex-end', gap: 8, flexDirection: 'row' } }}>
                        <Text css={{ color: 'gray3' }} className='text-2xl float-left'>
                            <FontAwesomeIcon icon={faCheckSquare} width={34} height={34} />
                            <Text className="text-l lg:text-lg xl:text-xl font-medium leading-normal mb-4">Comprehensive Investment Options</Text>
                        </Text>
                        <Text css={{ color: 'gray3' }} className='text-xl float-left'>
                            <FontAwesomeIcon icon={faCheckSquare} width={34} height={34} />
                            <Text className="text-l lg:text-lg xl:text-xl font-medium leading-normal mb-4">CPO Token Integration</Text>
                        </Text>
                        <Text css={{ color: 'gray3' }} className='text-xl float-left'>
                            <FontAwesomeIcon icon={faCheckSquare} width={34} height={34} />
                            <Text className="text-l lg:text-lg xl:text-xl font-medium leading-normal mb-4">Explore Thriving NFT Markets</Text>
                        </Text>
                        <Text css={{ color: 'gray3' }} className='text-xl'>
                            <FontAwesomeIcon icon={faCheckSquare} width={34} height={34} />
                            <Text className="text-l lg:text-lg xl:text-xl font-medium leading-normal mb-4">Expert Guidance</Text>
                        </Text>
                        <Text css={{ color: 'gray3' }} className='text-xl'>
                            <FontAwesomeIcon icon={faCheckSquare} width={34} height={34} />
                            <Text className="text-l lg:text-lg xl:text-xl font-medium leading-normal mb-4">User-Friendly Interface</Text>
                        </Text>
                        <Text css={{ color: 'gray3' }} className='text-xl'>
                            <FontAwesomeIcon icon={faCheckSquare} width={34} height={34} />
                            <Text className="text-l lg:text-lg xl:text-xl font-medium leading-normal mb-4">Risk Management and Security</Text>
                        </Text>
                        <Text css={{ color: 'gray3' }} className='text-xl'>
                            <FontAwesomeIcon icon={faCheckSquare} width={34} height={34} />
                            <Text className="text-l lg:text-lg xl:text-xl font-medium leading-normal mb-4">24/7 Support  </Text>
                        </Text>
                        <Text css={{ color: 'gray3' }} className='text-xl'>
                            <a href='/morereasion'> <Text className="font-medium leading-normal mb-4"> Read More  </Text></a>
                        </Text>
                    </Box>
                </Box>
            </Flex>

        </Box>
    )
}  