import { Text, Flex, Box, Button, Input } from '../primitives'
import InvestmenButton from './InvestmenButton'
export const GlobleInfo = () => {
    return (
        <Box style={{
            zIndex: '0', display: 'grid'
        }}
            css={{
                '@sm': {
                    px: '$0',
                },
            }}

        >
            <Flex className='flex flex-wrap'>
                <Box className='w-full px-4 md:w-9/12 lg:w-7/12 xl:w-8/12 '>
                    <Box css={{
                        borderStyle: 'solid',
                        alignItems: 'flex-start', px: '$1',
                        textAlign: 'center',
                        '@sm': { borderRight: '0px solid $gray7' },
                        gap: 18,
                        '@bp700': { borderRight: '1px solid $gray7', alignItems: 'flex-end', gap: 8 }
                    }}>

                        <Box className="wow fadeInUp mx-auto max-w-[800px] text-center p-5" data-wow-delay=".2s">
                            <h3 className="text-xl lg:text-2xl xl:text-3xl font-medium leading-normal uppercase mb-4"></h3>
                            <Text className="text-2xl lg:text-4xl xl:text-2xl font-medium leading-normal uppercase">We Were Always Thinking Global Community</Text>
                            <Box className='border-b-4 border-indigo-500 mt-5'></Box>
                            <p className="mt-4 text-base leading-relaxed text-left">
                                At Coinpulse Online Financial Service, our vision has always been centered around fostering a global community of investors and enthusiasts. From the very beginning, we recognized the importance of inclusivity and the power of bringing together individuals from diverse backgrounds, cultures, and perspectives. We firmly believe that by embracing a global mindset, we can unlock the full potential of our platform and create a vibrant ecosystem that transcends borders.
                            </p>

                        </Box>
                    </Box>
                </Box>
                <Box className='w-full px-4 md:w-3/12 lg:w-5/12 xl:w-4/12'>
                    <Flex justify='center' css={{ mt: '$2', flexDirection: 'row', alignItems: 'flex-start', '@bp600': { alignItems: 'flex-end' } }} >
                        <Box className='wow fadeInUp relative z-10 rounded-md bg-primary/[3%] p-8 dark:bg-primary/10 sm:p-11 lg:p-8 xl:p-11'>
                            <Box className='mb-11 border-b border-body-color border-opacity-25 pb-11 text-base font-medium  text-center content-center align-middle leading-relaxed text-body-color'>
                                <Text className='mb-4 text-2xl font-bold leading-tight'>Zero Commissions</Text>
                                <p>no hidden charges*</p>
                            </Box>
                            <Box className="relative opacity-30 lg:opacity-100 z-[-1] align-middle">
                                <InvestmenButton buttonChildren="Activate 2.1% Bot" planName='Gold Plan' planId='67c6aaa9-6f51-475a-b9f5-171df6791df9' duration={90} min={250} max={100000}/>
                            </Box>
                        </Box>
                    </Flex>
                </Box>
            </Flex>
        </Box>
    )
}  