import { Text, Flex, Box, Button, Input } from '../primitives'
import { faCheckSquare, faDollar, faNetworkWired, faTrophy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Img from '../primitives/Img'
export const ReferralPlan = () => {
    return (
        <Box style={{
            zIndex: '0', display: 'grid'
        }}
            css={{
                '@sm': {
                    px: '$0',
                },
            }}

        >
            <Flex
                justify="between"
                css={{
                    alignItems: 'center',
                    zIndex: '10',
                    flexDirection: 'column',
                    gap: 36,
                    '@bp600': {
                        flexDirection: 'row',
                        gap: 0,
                    },
                }}
            >

                <Box className='w-full px-4 xl:w-7/12 lg:w-7/12'>
                    <Box css={{ alignItems: 'flex-start', px: '$1', textAlign: 'center', gap: 18, '@bp600': { alignItems: 'flex-end', gap: 8 } }}>
                        <Box className="wow fadeInUp mx-auto max-w-[800px] text-center" data-wow-delay=".2s">
                            <h3 className="text-xl lg:text-2xl xl:text-3xl font-medium leading-normal uppercase"></h3>
                            <Text className="text-4xl lg:text-4xl xl:text-4xl font-medium leading-normal uppercase">Affiliate Program</Text>
                            <Box className='border-b-4 border-indigo-500 mt-5 mb-6'></Box>

                            <Box className='mb-10 flex  bg-primary bg-opacity-10 text-primary  flex-col justify-center items-center border-2 border-gray-500 rounded-xl py-4 px-2 '>
                                <Box className="flex items-center">
                                    <Box className="mr-5 flex items-center pr-5 xl:mr-3 xl:pr-3 2xl:mr-5 2xl:pr-5">
                                        <Box className="mr-4">
                                            <Box className="relative h-12 w-12 rounded-full flex bg-primary bg-opacity-10 text-primary flex-col justify-center items-center border-2 border-gray-500" css={{ backgroundColor: '$gray3' }}>
                                                <Text css={{ color: 'gray3' }} className='text-3xl float-left '>
                                                    <FontAwesomeIcon icon={faDollar} width={60} height={60} />
                                                </Text>
                                            </Box>
                                        </Box>
                                        <Box className="w-full">
                                            <h4 className="mb-1 text-lg font-medium text-dark">Up to 10% Commission</h4>
                                            <p className="text-base text-body-color">Earn a commission of up to 10% through our referral program, which operates on a 3-tier structure.</p>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box className='mb-10 flex  bg-primary bg-opacity-10 text-primary  flex-col justify-center items-center border-2 border-gray-500 rounded-xl py-4 px-2'>
                                <Box className="flex items-center">
                                    <Box className="mr-5 flex items-center pr-5 xl:mr-3 xl:pr-3 2xl:mr-5 2xl:pr-5">
                                        <Box className="mr-4">
                                            <Box className="relative h-12 w-12 rounded-full flex bg-primary bg-opacity-10 text-primary flex-col justify-center items-center border-2 border-gray-500" css={{ backgroundColor: '$gray3' }}>
                                                <Text css={{ color: 'gray3' }} className='text-3xl float-left '>
                                                    <FontAwesomeIcon icon={faTrophy} width={60} height={60} />
                                                </Text>
                                            </Box>
                                        </Box>
                                        <Box className="w-full">
                                            <h4 className="mb-1 text-lg  font-medium text-dark">3-tier Partner Network</h4>
                                            <p className="text-base text-body-color">Earn commissions not only from your direct referrals but also from the referrals made by your sub-affiliates.</p>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box className='mb-10 flex  bg-primary bg-opacity-10 text-primary  flex-col justify-center items-center border-2 border-gray-500 rounded-xl py-4 px-2'>
                                <Box className="flex items-center">
                                    <Box className="mr-5 flex items-center pr-5 xl:mr-3 xl:pr-3 2xl:mr-5 2xl:pr-5">
                                        <Box className="mr-4">
                                            <Box className="relative h-12 w-12 rounded-full flex bg-primary bg-opacity-10 text-primary flex-col justify-center items-center border-2 border-gray-500" css={{ backgroundColor: '$gray3' }}>
                                                <Text css={{ color: 'gray3' }} className='text-3xl float-left '>
                                                    <FontAwesomeIcon icon={faNetworkWired} width={60} height={60} />
                                                </Text>
                                            </Box>
                                        </Box>
                                        <Box className="w-full">
                                            <h4 className="mb-1 text-lg  font-medium text-dark"><a href='/bonusProgram' target='_blank' className='text-blue-500'>Up to $3,00,000.00 bonus</a> </h4>
                                            <p className="text-base text-body-color">Unlock the extraordinary opportunity to earn an incredible bonus of up to <a href='/bonusProgram' target='_blank' className='text-blue-500'><b>$3,00,000.00</b></a>  with Coinpulse Online Financial Service Affiliate Ranking program..</p>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>  
                        </Box>
                    </Box>
                </Box>
                <Box css={{ borderLeft: '1px solid $gray7', borderStyle: 'solid', }} className='w-full px-4  lg:w-5/12 xl:w-5/12 hidden lg:block xl:block'>
                    <Flex justify='center' css={{ mt: '$2', flexDirection: 'row', alignItems: 'flex-start', '@bp600': { alignItems: 'flex-end' } }} >
                        <Box className='wow fadeInUp relative z-10 rounded-md bg-primary/[3%] p-8 dark:bg-primary/10 sm:p-11 lg:p-8 xl:p-11 bottom-0 right-0'>
                            <img src='/referralPlan.png' alt='' />
                        </Box>
                    </Flex>
                </Box>
            </Flex>

        </Box>
    )
}  