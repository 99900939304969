import { Text, Flex, Box, Button, Input } from '../primitives' 
import { Carousel } from '@trendyol-js/react-carousel'
export const PaymentMethod = () => {
    return (
        <Box style={{
            zIndex: '0', display: 'grid'
        }}
            css={{
                '@sm': {
                    px: '$0',
                },
            }}

        >
            <Flex
                css={{
                    alignItems: 'center',
                    zIndex: '10',
                    flexDirection: 'column',
                    gap: 36,
                    '@bp600': {
                        flexDirection: 'row',
                        gap: 0,
                    },
                }}
            >
                <Box className='w-full px-4'>
                    <Box css={{ alignItems: 'flex-start', px: '$1', gap: 18, '@bp600': { alignItems: 'flex-end', gap: 8 } }}>
                        <Box className="wow fadeInUp mx-auto max-w-7xl p-5" data-wow-delay=".2s">
                            <Text className="text-2xl lg:text-4xl xl:text-2xl font-medium leading-normal uppercase">Deposit Payment Method</Text>
                            <Box className='border-b-4 border-indigo-500 mt-5 mb-5'></Box>
                            <Box className="grid grid-cols-2 lg:grid-cols-7 gap-4">
                                <Box className=''>
                                    <img src='/usdt.png' alt='' className='h-32 w-32'/>
                                </Box>
                                <Box className=''>
                                    <img src='/btc.png' alt=''  className='h-32 w-32'/>
                                </Box>
                                <Box className=''>
                                    <img src='/ltc.png' alt=''  className='h-32 w-32'/>
                                </Box>
                                <Box className=''>
                                    <img src='/eth.png' alt=''  className='h-32 w-32'/>
                                </Box>
                                <Box className=''>
                                    <img src='/doge.png' alt=''  className='h-32 w-32'/>
                                </Box>
                                <Box className=''>
                                    <img src='/app.png' alt=''  className='h-32 w-32'/>
                                </Box>
                                <Box className=''>
                                    <img src='/bch.png' alt=''  className='h-32 w-32'/>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Flex>
        </Box>
    )
}  